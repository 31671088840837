import styled from 'styled-components';

export const VideosSection = styled.section`
  background-color: ${({ theme }) => theme.colors.sapphireBlue};
  color: ${({ theme }) => theme.colors.white};

  iframe {
    height: 240px;

    @media (min-width: 768px) {
      height: 300px;
      width: 100%;
    }
  }

  h3 {
    margin-top: 16px;
  }

  p {
    margin-top: 5px;
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    padding: 16px 0 32px;
  }
`;

export const StyledH1 = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 24px;

  @media (min-width: 992px) {
    margin-bottom: 48px;
  }
`;

import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Video from "../components/Video/video"
import parse from 'html-react-parser'
import BreadCrumbs from '../components/BreadCrumbs/BreadCrumbs';
import Button from '../components/Button/Button';
import { Container, Row, Col } from 'react-bootstrap';
import { VideosSection, StyledH1 } from '../styles/videosPageStyles';

const VideoPage = ( { data } ) => {
  const [videosToShow, setvideosToShow] = useState(4);
  const allVideos = data.allContentfulVideo.edges;
  const slicedVideosList = allVideos.slice(0, videosToShow);

  const handleViewMore = () => {
    setvideosToShow(videosToShow + 4);
  };

  const renderViewMore = (videosToShow, allVideosLenth, buttonText) => {
    const props = { buttonText: buttonText, onClick: handleViewMore };
    if (videosToShow.length < allVideosLenth.length) {
      return (
        <Button {...props} />
      )
    }
  }

  const renderVideos = () => {
    return slicedVideosList.map( video => {
      return (
        <Col xs={12} md={6} key={video.node.id} className="my-4">
          <Video
              videoSrcURL={video.node.videoUrl}
              videoTitle={video.node.videoTitle.childMarkdownRemark.html}
            />
          {parse(video.node.videoTitle.childMarkdownRemark.html)}
          <p>{video.node.videoSubtitle}</p>
        </Col>
      )
    } )
  }
  return(
    <Layout>
      <SEO title="Videos" />
      <VideosSection>
        <Container>
          <BreadCrumbs archiveLink={"Videos"} color={"white"}/>
          <StyledH1>Videos</StyledH1>
          <Row>
            {renderVideos()}
          </Row>
          <div className="buttonContainer">
            {renderViewMore(slicedVideosList, allVideos, 'View More')}
          </div>
        </Container>
      </VideosSection>
    </Layout>
  )
}

export default VideoPage;

export const pageQuery = graphql`
  query videoPageQuery {
    allContentfulVideo {
      edges {
        node {
          videoUrl
          videoTitle {
            childMarkdownRemark {
              html
            }
          }
          videoSubtitle
          id
        }
      }
    }
  }
`
